@import './styles/custom-scrollbar.scss';
@import './styles/theme.scss';
@import './styles/custom-material.scss';
@import './styles/colors.scss';


html,
body {
  height: 100%;
}

.mat-sidenav {
  width: 300px;
} 

body {
  margin: 0;
  font-family: 'Roboto', sans-serif;
  overflow: hidden;
  // background-color: var(--surface) !important;
}

.header {
  justify-content: space-between;
}

.mat-sidenav-container {
  display: flex;
  flex: 1 1 auto;
}

.mat-nav-list .mat-list-item {
  font-size: 15px;
}

.nav-tool-items {
  display: inline-block;
  margin-right: 13px;
}

.mat-sidenav,
.mat-sidenav-content {
  padding: 0;
}

.mat-list-item.active {
  background: rgba(0, 0, 0, .04);
}

.mat-sidenav {
  background-color: #F2F2F2;
  width: 250px;
}

.header {
  position: sticky;
  position: -webkit-sticky;
  top: 0;
  z-index: 1000;
}

mat-sidenav mat-icon {
  margin-right: 12px;
}

.hamburger {
  margin-top: 5px;
  cursor: pointer;
}

.mat-radio-button,
.mat-radio-group {
  margin-right: 25px;
}

.controlers-wrapper>* {
  width: 100%;
  padding: 0;
}

.misc-bottom-padding {
  margin: 8px 0 10px;
}

.misc-bottom-padding mat-label {
  margin-right: 15px;
}

mat-radio-group mat-radio-button {
  margin-left: 5px;
}

.button-wrapper button {
  margin-right: 5px;
}

table.mat-table,
table {
  width: 100%;
}

body .mat-list-item {
  margin-bottom: 10px;
}

.inner-wrapper {
  padding: 15px 0 130px;
  width: 100%;
}

.inner-wrapper mat-card {
  display: inline-block;
  margin: 0 6% 0 0;
  vertical-align: top;
  width: 44%;
}

.full-wrapper {
  width: 100%;
}

.multiple-items {
  position: relative;
}

.multiple-items .tooltip-info {
  right: 0;
  top: 7px;
  cursor: pointer;
  color: #a1a7c7;
  position: absolute;
  font-size: 20px;
}

body .push-right {
  margin-right: 10px;
}

.no-data {
  text-align: center;
  padding-top: 30px;
  color: #6c75a9;
}

.button-wrapper {
  margin: 20px 0 0 0;
}

.example-card {
  max-width: 400px;
}

.example-header-image {
  background-image: url('https://material.angular.io/assets/img/examples/shiba1.jpg');
  background-size: cover;
}

.title-group {
  margin-bottom: 25px;
}

.card-deck-container {
  width: 100%;
  max-width: 1200px;
  position: relative;
  border-radius: 2px;
  padding: 10px 10px 30px;
  margin: 10px 10px 10px 10px;
  background-color: #f5f5f5;
}

.card-item {
  padding: 3px 3px 3px 3px;
}

mat-grid-tile {
  background: lightblue;
}

.my-alternate-theme button {
  margin-right: 10px;
}

@media (max-width:1024px) {
  .inner-wrapper mat-card {
    width: 100%;
  }

  .mat-sidenav-content {
    padding: 0;
  }

  .misc-bottom-padding mat-label {
    display: block;
    padding-bottom: 10px;
  }

  .mat-sidenav {
    width: 230px;
  }

  .mat-nav-list .mat-list-item {
    font-size: 14px;
  }
}

@media (max-width:767px) {
  .nav-tool-items {
    margin-right: 0;
  }

  .hamburger {
    visibility: visible !important;
  }
}

